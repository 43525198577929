@import '~@amzn/awsui-components-react/sass/constants';

.awsui awsui-toggle {
    display: inline-block;
    margin-left: 10px;
}

.preloaded-lab-status {
    color: $awsui-green-600;
}

.failed-lab-status {
    color: $awsui-red-600;
}
