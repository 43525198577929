.class-form {
    padding: 2rem 4rem;
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
}

.class-form__time-input {
    display: inline-block;
}

.class-form__error-message {
    color: var(--awsui-color-text-status-error, #d13212) !important;
}
.awsui-row > .class-form__error-message {
    padding: 0 1rem;
}

input[type='time']:disabled {
    border: none;
    background-color: var(--awsui-color-background-input-disabled, #eaeded);
    color: var(--awsui-color-text-input-disabled, #879596);
}

.info-link {
    /* remove button styling */
    background: none!important;
    border: none;
    padding: 0!important;
    /* add link styling */
    font-weight: bold;
    color: #069;
    cursor: pointer;
}