.site-header {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.site-header button a {
    color: inherit;
}
