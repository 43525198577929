.instructor-field {
    padding: 0.5em 0;
}

.instructor-fields-wrapper {
    padding-bottom: 1.5rem;
}

.instructor-assign-fieldset {
    border: 0;
    padding: 0 0 0;
    margin: 1rem 0;
}
