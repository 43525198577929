.providers-page {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.providers-page__body {
    flex: 0 1 80%;
    max-width: 60rem;
}

.providers-page__body__inner {
    padding-bottom: 6rem !important;
}

.providers-page__body__inner ul {
    list-style-type: none;
    padding: 0;
}

.awsui .providers-page__button,
.awsui .providers-page__button button {
    width: 100%;
}

.awsui .providers-page__button button {
    padding: 2rem;
}
