@import 'styles/common-mixins';
[data-testid='section-header'] {
    @include opposites();
    align-items: flex-end;
    margin-bottom: 1rem;
}

[data-testid='section-header'] .navigation-dropdown {
    padding-right: 0;
}
