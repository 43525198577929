.class-page .class-page__body {
    padding: 32px 16px 80px;
    max-width: 1280px;
    margin: auto;
}

.class-page .content-header {
    margin-bottom: 8px !important;
}

.class-page__early-warning {
    font-style: italic;
}

@media screen and (max-width: 992px) {
    .class-page .description {
        margin-top: 0;
    }
}
