.class-detail__flex-between {
    display: flex;
    justify-content: space-between;
}

.class-detail__container dd {
    margin-left: 1em;
}

.awsui .class-detail__action-btn button {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navigation-dropdown {
    padding: .5rem;
}
