@import '~@amzn/awsui-components-react/sass/constants';

.banner-header {
    padding: 64px 0;
    background-image: url('./at2-spr-banner-classroom.png');
    background-size: cover;
    background-position: center;
}

.awsui .banner-title {
    color: $awsui-white;
    font-weight: 500;
    font-size: 4rem;
    width: 90%;
    margin: auto;
    max-width: 1248px;
}
