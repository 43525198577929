.footer {
    background-color: #fff;
}

.footer__body {
    margin-left: auto;
    margin-right: auto;
    max-width: 976px;
    text-align: center;
}

.footer__body__links > * {
    display: inline-block;
}

.footer__body__links > * + * {
    margin-left: 20px;
    position: relative;
    padding-top: 0 !important;
}

.footer__body__links > * + *:before {
    content: '';
    height: 14px;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    background-color: rgb(35, 47, 62);
    position: absolute;
}

@media (min-width: 659px) {
    .footer__body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .footer__body__links {
        text-align: left;
    }
}

@media (min-width: 1201px) {
    .footer__body {
        max-width: 1248px;
    }
}
